import React, { useCallback, useRef, useState } from 'react'
import { css } from '@emotion/core'
import { useDrag } from '../../../hooks/useDrag'
import { useHover } from '../../../hooks/useHover'

const wrapperStyles = css`
  user-select: none;
  position: relative;
  padding: 8px 8px 0 8px;
`

const rangeStyles = (disabled: boolean, dragging: boolean) => css`
  user-select: none;
  width: 100%;
  height: 20px;
  position: relative;
  margin-left: 4px;
  cursor: ${disabled ? 'default' : dragging ? 'grabbing' : 'grab'};
`

const backgroundStyles = css`
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 5px;
`

const barStyles = css`
  user-select: none;
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  height: 2px;
  background-color: #0e1111;
  border-right: 3px solid #fff;
  box-sizing: border-box;
  pointer-events: none;
`

const hoverBarStyles = (hovered: boolean) => css`
  user-select: none;
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  height: 2px;
  background-color: ${hovered ? '#00000070' : '#00000040'};
  transition: background-color 0.2s ease;
  pointer-events: none;
`

const thumbStyles = (hovered: boolean, dragging: boolean, disabled: boolean) => css`
  user-select: none;
  position: absolute;
  top: 50%;
  width: 16px;
  height: 16px;
  background-color: #8053fd;
  border-radius: 50%;
  display: ${disabled ? 'none' : 'block'};
  cursor: ${disabled ? 'default' : dragging ? 'grabbing' : 'grab'};
  transition: transform 0.2s ease, background-color 0.2s ease, opacity 0.2s ease;
  transform: translate(-50%, -50%) scale(${hovered || dragging ? '1.2' : '1'});
  opacity: 100%;
  box-shadow: 0 2px 1px -1px rgba(60, 70, 76, 0.08), 0 1px 3px 0 rgba(128, 83, 253, 0.28),
    0 1px 1px 0 rgba(41, 45, 47, 0.1);
  opacity: ${dragging ? '0.6' : '1'};
  &:before {
    position: absolute;
    top: 4px;
    left: 6px;
    content: ' ';
    height: 8px;
    width: 1px;
    opacity: 0.4;
    background-color: #ffffff;
  }
  &:after {
    position: absolute;
    top: 4px;
    left: 9px;
    content: ' ';
    height: 8px;
    width: 1px;
    opacity: 0.4;
    background-color: #ffffff;
  }
`

const optionsStyle = css`
  position: relative;
  pointer-events: none;
`
const optionStyle = (
  hasStartCircle: boolean,
  hasEndCaret: boolean,
  isFirst: boolean = false,
  isLast: boolean = false
) => css`
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  border-right: 2px solid #000;
  ${
    isFirst
      ? `{
    border-left: 2px solid #000;
    border-right: none;
  }`
      : ''
  }
  ${
    isFirst && hasStartCircle
      ? `
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 2px solid #000;
      border-radius: 50%;
      box-shadow: 0 0 1px 0px rgb(255, 255, 255);
      margin-left: -8px;
    `
      : ''
  }
  ${
    isLast && hasEndCaret
      ? `
        display: inline-block;
        width: 7px;
        height: 7px;
        border: solid #000;
        border-width: 0 1px 1px 0;
        margin-top: 1px;
        transform: rotate(-45deg);
    `
      : ''
  }
`
const dotStyle = css`
  height: 2px;
  width: 2px;
  background-color: #000;
  margin-top: 4px;
  &:first-child,
  &:last-child {
    background-color: transparent;
  }
`

const dotsStyle = css`
  display: flex;
  justify-content: space-between;
  pointer-events: none;
`

export interface Props {
  value?: number
  min?: number
  max?: number
  options?: any
  dotsEvery?: number
  disabled?: boolean
  hasStartCircle?: boolean
  hasEndCaret?: boolean
  onChange?: (value: number) => void
}

const getClosestOption = (value: number, options: any) =>
  Number(
    Object.keys(options).sort((a: any, b: any) => Math.abs(value - a) - Math.abs(value - b))[0]
  )

const getPercent = (value: number, min: number, max: number) => ((value - min) / (max - min)) * 100

const swapObjectKeyValues = (obj: any) =>
  Object.assign({}, ...Object.entries(obj).map(([a, b]: [any, any]) => ({ [b]: Number(a) })))

const fromNumberToArray = (num: number) => [...Array(num).keys()]

export const RangeSlider: React.FC<Props> = ({
  value = 0,
  min = 0,
  max = 100,
  dotsEvery = 0,
  hasStartCircle = false,
  hasEndCaret = false,
  options,
  disabled,
  onChange
}) => {
  const optionValueToKey = options ? swapObjectKeyValues(options) : undefined
  const realValue = options ? Number(optionValueToKey[value]) : value
  const ref = useRef<HTMLInputElement>(null)
  const [hovered, hoveredX] = useHover(ref, disabled)
  const [shadowThumbValue, setShadowThumbValue] = useState(realValue)
  const [isDragging, handleDragStart] = useDrag({
    anchorRef: ref,
    handleRef: ref,
    onMove: useCallback(
      ({ px }: { px: number }) => {
        if (onChange) {
          const normalizedPercent = Math.max(min, Math.min(max, Math.floor(min + px * max)))

          if (options) {
            const closestValue: number = getClosestOption(normalizedPercent, options)
            onChange(options[closestValue])
          } else {
            onChange(normalizedPercent)
          }

          setShadowThumbValue(normalizedPercent)
        }
      },
      [max, onChange]
    ),
    disabled
  })

  return (
    <div css={wrapperStyles}>
      <div
        ref={ref}
        css={rangeStyles(!!disabled, isDragging)}
        onMouseDown={handleDragStart}
        onTouchStart={handleDragStart}
      >
        <div css={backgroundStyles}>
          <div css={hoverBarStyles(false)} style={{ width: '100%' }} />
          <div
            css={hoverBarStyles(hovered)}
            style={{ width: Math.min(hoveredX * 100, 100) + '%' }}
          />
          <div
            css={barStyles}
            style={{
              display: !!disabled ? 'none' : 'block',
              left: '-4px',
              width: `${isDragging ? getPercent(shadowThumbValue, min, max) : realValue}%`
            }}
          />
          {options ? (
            <div css={optionsStyle}>
              {Object.keys(options).map((option: any, index: number) => (
                <div
                  css={optionStyle(
                    !!hasStartCircle,
                    !!hasEndCaret,
                    index === 0,
                    index === Object.keys(options).length - 1
                  )}
                  style={{ left: `calc(${option}% - ${!!hasEndCaret ? '6px' : '4px'})` }}
                  key={option}
                />
              ))}
            </div>
          ) : null}
          {dotsEvery ? (
            <div css={dotsStyle}>
              {fromNumberToArray(Math.floor((max - min) / dotsEvery)).map(index => (
                <div css={dotStyle} key={index} />
              ))}
            </div>
          ) : null}
        </div>
        <div
          css={thumbStyles(hovered, isDragging, !!disabled)}
          style={{
            left: `calc(${isDragging ? getPercent(shadowThumbValue, min, max) : realValue}% - 4px)`
          }}
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
        />
      </div>
    </div>
  )
}
